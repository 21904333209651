import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Pagination from "../components/Pagination/pagination"
import SEO from "../components/seo"
import BannerBox from "../components/BannerBox/BannerBox"
import TabsCustom from "../components/TabsCustom/TabsCustom"

import artificialIntelligenceBanner from "../assets/img/CategoryBanner/artificial-intelligence.jpg"

const CategoryTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const [allPost, setAllPost] = useState([])
  const [listPost, setListPost] = useState([])
  const [activeCategories, setActiveCategories] = useState([])
  const [currentPageSearch, setCurrentPageSearch] = useState(1)
  const [totalCountSearch, setTotalCountSearch] = useState(0)
  const [isSearch, setIsSearch] = useState(false)

  useEffect(() => {
    const dataBlog = data.allWpPost.nodes.map(post => {
      const featuredImage = post.featuredImage?.node?.sourceUrl
      const thumbnail = featuredImage && featuredImage.indexOf("/wp-content/") === 0
        ? `https://neurond-wp.azurewebsites.net/${featuredImage}`
        : featuredImage
      return {
        ...post,
        thumbnail,
      }
    })

    setListPost(dataBlog)

    const allCategory = data.allWpCategory.nodes.filter(category => category.count)
    setActiveCategories([{ slug: "all", name: "All" }, ...allCategory])

    const dataAllPost = pageContext.enBlogs?.map(post => {
      const featuredImage = post.featuredImage?.node?.sourceUrl
      return {
        ...post,
        thumbnail:
          featuredImage && featuredImage.indexOf("/wp-content/") === 0
            ? `https://neurond-wp.azurewebsites.net/${featuredImage}`
            : featuredImage,
      }
    })

    setAllPost(dataAllPost || [])
  }, [])

  const { categoryData } = pageContext
  const backgroundImage = categoryData?.CategoryCustomFields?.thumbnail?.sourceUrl || artificialIntelligenceBanner
  const bannerData = {
    title: categoryData?.name,
    content: categoryData?.description
  }
  return (
    <Layout>
      <SEO title={t("Blog")} canonical={data.site.siteMetadata.siteUrl + "/categories/" + categoryData?.slug} />
      <section className={"blog-zone margin-bot-2"}>
        <section
          className="categoriesBanner margin-bot-2"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="wrapper customBannerBox">
            <BannerBox item={bannerData} />
          </div>
        </section>
        <section className="wrapper">
          {/* <article className="firstArticle">
            <ArticleCard item={firstArticle} index={1} />
          </article> */}
          {/* <div className="wrapper__row">
            {blogCard.map((blogData, index) => {
              return (
                <div
                  key={index}
                  className="wrapper__row__col-md-4 blog-custom-card"
                >
                  <CategoryCard item={blogData} key={index} />
                </div>
              )
            })}
          </div> */}
          <TabsCustom
            categoriesData={activeCategories}
            tabData={listPost}
            currentTabSlug={pageContext.slug}
            allPost={allPost}
            setIsSearch={setIsSearch}
            setTotalCountSearch={setTotalCountSearch}
            currentPageSearch={currentPageSearch}
            setCurrentPageSearch={setCurrentPageSearch}
            limit={pageContext?.limit}
          />
        </section>
        <Pagination
          pageContext={pageContext}
          prefix="categories"
          totalCount={data.allWpPost.totalCount}
          totalCountSearch={totalCountSearch}
          setCurrentPageSearch={setCurrentPageSearch}
          currentPageSearch={currentPageSearch}
          isSearch={isSearch}
        />
      </section>
    </Layout>
  )
}

export default CategoryTemplate
export const query = graphql`
  query($limit: Int, $skip: Int, $slug: String) {
    allWpCategory {
      nodes {
        slug
        name
        description
        count
      }
    }
    allWpPost(
      limit: $limit
      skip: $skip
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      nodes {
        title
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
        slug
        categories {
          nodes {
            slug
          }
        }
      }
      totalCount
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
